<template>
  <div class="my-20">
    <h3 class="text-xl">
      All projects
    </h3>
    <ul class="my-20">
      <li v-for="p in projects" :key="p._id">
        <router-link class="underline" :to="`/${ p.slug.current }`">{{ p.title }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import sanity from '../client.js'

const query = `*[_type == 'project'] {
  _id,
  title,
  slug,
}
`;


export default defineComponent({
  name: 'Home',
  components: {
  },
  data() {
    return {
      loading: true,
      projects: [],
      error: null,
    }
  },
  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.error = null
      this.loading = true

      sanity.fetch(query).then(
        (projects) => {
          this.loading = false;
          this.projects = projects;
        },
        (error) => {
          this.error = error;
        }
      );
    },
  },

});
</script>
